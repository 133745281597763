import axios from 'axios';
import { ApiResult } from 'interfaces/APITypes';
import { CreateSetPayload } from 'interfaces/SetTypes';
import {
  ContentSet,
  ContentSetParams,
  GameResource,
  NamedCropDimensions,
  StudioFile,
  StudioUnit,
  TagParams,
  UpdateSetPayload,
} from 'interfaces/StudioTypes';
import { Unit } from 'interfaces/Unit';

import { buildAxiosResult } from 'utils/axios';

import { ServerSideFilter } from '../components/mytover/invite/UnitUserFilterSection';

export const contentInstance = axios.create({
  baseURL: process.env.REACT_APP_CONTENT_API,
  withCredentials: true,
});

export class ContentAPI {
  static async getSets(values?: ContentSetParams): Promise<ApiResult> {
    const response = await contentInstance.get('/content-sets', {
      params: values,
    });
    return await buildAxiosResult(response);
  }

  static async getSetSections(): Promise<ApiResult> {
    const response = await contentInstance.get('/content-sets/gallery');
    return await buildAxiosResult(response);
  }

  static async attachPremiumSet(id: string, units: Unit[]): Promise<ApiResult> {
    const payload = {
      units: units.map((unit) => unit.serial_number),
    };
    const response = await contentInstance.post(`/content-sets/${id}/attach-units`, payload);
    return await buildAxiosResult(response);
  }

  static async detachPremiumSet(id: string, units: StudioUnit[]): Promise<ApiResult> {
    const payload = {
      units: units.map((unit) => unit.serial_number),
    };
    const response = await contentInstance.post(`/content-sets/${id}/detach-units`, payload);
    return await buildAxiosResult(response);
  }

  static async getUnit(id: string, params: any = null): Promise<ApiResult> {
    const response = await contentInstance.get(`/units/${id}`, { params: params });
    return await buildAxiosResult(response);
  }

  static async getSetsForUnit(id: string): Promise<ApiResult> {
    const response = await contentInstance.get(`/units/${id}/content-sets`);
    return await buildAxiosResult(response);
  }

  static async getSetsForUser(id: string): Promise<ApiResult> {
    const response = await contentInstance.get(`/users/${id}/content-sets`);
    return await buildAxiosResult(response);
  }

  static async syncSetsForUnit(id: string, sets: ContentSet[]): Promise<ApiResult> {
    const payload = {
      packs: sets.map((s) => s.id),
    };
    const response = await contentInstance.post(`/units/${id}/content-sets`, payload);
    return await buildAxiosResult(response);
  }

  static async getSetDetail(id: string, settings?: { translations?: number }): Promise<ApiResult> {
    const response = await contentInstance.get(`/content-sets/${id}`, { params: settings });
    return await buildAxiosResult(response);
  }

  static async getSyncedUnitsForPack(id: string): Promise<ApiResult> {
    const response = await contentInstance.get(`/content-sets/${id}/units`);
    return await buildAxiosResult(response);
  }

  static async setSyncedUnitsForPack(id: string, units: StudioUnit[]): Promise<ApiResult> {
    const payload = {
      units: units.map((u) => u.configuration_key),
    };
    const response = await contentInstance.post(`/content-sets/${id}/sync-units`, payload);
    return await buildAxiosResult(response);
  }

  static async createSet({ game, images, ...values }: CreateSetPayload): Promise<ApiResult> {
    const payload = {
      ...values,
      game: game.code,
      images: images.map((image: any) => {
        return {
          id: image.id,
          crop: image.crop,
        };
      }),
    };

    const response = await contentInstance.post('/content-sets', payload);
    return await buildAxiosResult(response);
  }

  static async updateSet(set: UpdateSetPayload, images?: StudioFile[]): Promise<ApiResult> {
    const payload = {
      name: set.name,
      ...(set.translations ? { translations: set.translations } : {}),
      ...(set.featuredImage ? { featuredImage: set.featuredImage } : {}),
      ...(set.tags ? { tags: set.tags } : {}),
      ...(images ? { images } : {}),
      ...(set.public !== undefined ? { public: set.public } : {}),
      ...(set.level !== undefined ? { level: set.level } : {}),
    };

    const response = await contentInstance.patch(`/content-sets/${set.id}`, payload);
    return await buildAxiosResult(response);
  }

  static async getGamesPerUnit(id: string, serial: string): Promise<ApiResult> {
    const response = await contentInstance.get(`/content-sets/${id}/units/${serial}`);
    return await buildAxiosResult(response);
  }

  static async setGamesPerUnit(
    id: string,
    serial: string,
    games: string[],
  ): Promise<ApiResult<{ games: GameResource[] }>> {
    const response = await contentInstance.post(`/content-sets/${id}/units/${serial}`, { games });
    return await buildAxiosResult(response);
  }

  static async deleteSet(set: ContentSet): Promise<ApiResult> {
    const response = await contentInstance.delete(`/content-sets/${set.id}`);
    return await buildAxiosResult(response);
  }

  static async deleteSetFromUnit(set: ContentSet, unit: Unit): Promise<ApiResult> {
    const response = await contentInstance.delete(`/units/${unit.serial_number}/content-sets/${set.id}`);
    return await buildAxiosResult(response);
  }

  static async getGames(): Promise<ApiResult> {
    const response = await contentInstance.get('/games');
    return await buildAxiosResult(response);
  }

  static async uploadImage(file: File): Promise<ApiResult> {
    const formData = new FormData();
    formData.append('image', file, file.name);

    const response = await contentInstance.post('/upload-image', formData);
    return await buildAxiosResult(response);
  }

  static async updateImage({
    pack,
    uuid: id,
    crop,
  }: {
    pack: string;
    uuid: string;
    crop: NamedCropDimensions;
  }): Promise<ApiResult> {
    const response = await contentInstance.patch(`/content-sets/${pack}/images/${id}`, { crop });
    return await buildAxiosResult(response);
  }

  static async removeImage(set: ContentSet, file: StudioFile): Promise<ApiResult> {
    const response = await contentInstance.delete(`/content-sets/${set.id}/images/${file.id}`);
    return await buildAxiosResult(response);
  }

  // Tags
  static async getTags(values?: TagParams): Promise<ApiResult> {
    const response = await contentInstance.get('/tags', {
      params: values,
    });
    return await buildAxiosResult(response);
  }

  // Activity
  static async getUser(id?: string): Promise<ApiResult> {
    const response = await contentInstance.get(`/users/${id}`);
    return await buildAxiosResult(response);
  }

  static async getUnitActivity(id?: number | string, values?: ServerSideFilter): Promise<ApiResult> {
    const response = await contentInstance.get(`/units/${id}/activity`, { params: values });
    return await buildAxiosResult(response);
  }
}
